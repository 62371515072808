import { useEffect, useState } from 'react';
import { useMeQuery, useListProgramsQueryWithParams, useListProgramsQueryWithParamsCsv } from 'hooks/queries';
import useScopeList from './useScopeList';
import { roleList } from 'utils/constUtils';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import api from 'api';
import { useAlert } from 'common/alertContext';
import FileSaver from 'file-saver';

const useViewSubscription = ({ initialFilterState }) => {
  const [filterSubscriptionState, setFilterSubscriptionState] = useState(initialFilterState);
  const [typeExportData, setTypeExportData] = useState();
  const queryClient = useQueryClient();
  let query = useListProgramsQueryWithParams(initialFilterState);

  const filesName = {
    programs: 'Program_export',
    'programs-sessions': 'Program_export_with_sessions',
    'programs-objectives': 'Program_export_with_objectives',
  };

  const {
    data: dataCsv,
    refetch: refetchCsv,
    isLoading: isLoadingExportData,
    isRefetching,
  } = useListProgramsQueryWithParamsCsv({ ...initialFilterState, csv: typeExportData });

  const { data: user } = useMeQuery();
  const { setStateAlert, stateAlert } = useAlert();

  const [programId, setProgramId] = useState('');
  const [authorizeExportDataDownload, setAuthorizeExportDataDownload] = useState(false);
  const [userId, setUserId] = useState('');
  const [requestBody, setRequestBody] = useState({
    accept: true,
  });

  const { scopeOption } = useScopeList({
    enabled: [roleList.RH, roleList.RH_MANAGER].includes(user.role),
  });

  const updateFilterSubscription = (newValue) => {
    return setFilterSubscriptionState({
      ...newValue,
    });
  };

  const mutationCoachingRequest = useMutation(api.Programs.respond, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['list-programs']);
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
      queryClient.invalidateQueries(['list-programs']);
    },
  });

  const { mutate: mutationInvite, isFetched } = useMutation(api.Guests.invite, {
    onSuccess: (data) => {
      console.log('body : ', { ...requestBody, coacheeId: data.id });
      mutationCoachingRequest.mutate({ id: programId, body: { ...requestBody, coacheeId: data.id } });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
      queryClient.invalidateQueries(['list-programs']);
    },
  });

  const handleCoachingRequest = (guestId, programId, body) => {
    setProgramId(programId);
    setRequestBody(body);
    mutationInvite(guestId);
  };

  const reloadListPrograms = () => {
    queryClient.invalidateQueries(['list-programs']);
  };

  useEffect(() => {
    if (dataCsv && authorizeExportDataDownload && !isLoadingExportData && !isRefetching) {
      const blob = new Blob([dataCsv], { type: 'text/csv' });
      FileSaver.saveAs(blob, `${filesName[typeExportData]}.csv`);
      setAuthorizeExportDataDownload(false);
      setTypeExportData(null);
    }
  }, [dataCsv, authorizeExportDataDownload, isLoadingExportData, isRefetching]);

  const downloadCsv = (type) => {
    setTypeExportData(type);
    setAuthorizeExportDataDownload(false);

    // Waits for inite params to be updated in query hook
    setTimeout(() => {
      refetchCsv();
      setAuthorizeExportDataDownload(true);
    }, 100);
  };

  return {
    state: {
      filterSubscriptionState,
      scopeOption,
      isLoadingExportData: (isLoadingExportData && authorizeExportDataDownload) || isRefetching,
    },
    query: query,
    actions: { reloadListPrograms, updateFilterSubscription, handleCoachingRequest, downloadCsv },
  };
};

export default useViewSubscription;
