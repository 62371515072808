import axios from 'axios';

export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? `https://${process.env.REACT_APP_API_URL}`
    : `http://${process.env.REACT_APP_API_URL}`;

const getAuthToken = () => localStorage.getItem('REACT_TOKEN_AUTH');
const getRefreshToken = () => localStorage.getItem('REACT_REFRESH_TOKEN_AUTH');

axios.defaults.headers.common['Authorization'] = `Bearer ${getAuthToken()}`;

const refreshTokenRequest = async (refreshToken = getRefreshToken()) => {
  if (!refreshToken) return false;

  try {
    const res = await axios.post(`${BASE_URL}/api/auth/refresh-token`, { refreshToken });

    if (res?.data?.data?.accessToken) {
      localStorage.setItem('REACT_TOKEN_AUTH', res.data.data.accessToken);
      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const redirectLogin = () => {
  localStorage.removeItem('REACT_TOKEN_AUTH');
  localStorage.removeItem('REACT_REFRESH_TOKEN_AUTH');

  const { host, protocol } = window.location;
  window.location.replace(`${protocol}//${host}/login#disconnection`);
};

const _get = async (endpoint, queryParams = {}, options, token = getAuthToken()) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const res = await axios.get(`${BASE_URL}/api${endpoint}`, { params: queryParams, ...options });
  return res.data;
};

const _blob = async (endpoint, queryParams = {}, options, token = getAuthToken()) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const res = await axios.get(`${BASE_URL}/api${endpoint}`, { params: queryParams, ...options, responseType: 'blob' });
  return res.data;
};

const _post = async (endpoint, body = {}, queryParams = {}, options, token = getAuthToken()) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const res = await axios.post(`${BASE_URL}/api${endpoint}`, body, {
    params: queryParams,
    ...options,
  });
  return res.data;
};

const _patch = async (endpoint, body = {}, queryParams = {}, token = getAuthToken()) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const res = await axios.patch(`${BASE_URL}/api${endpoint}`, body, { params: queryParams });
  return res.data;
};

const _put = async (endpoint, body = {}, queryParams = {}, token = getAuthToken()) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const res = await axios.put(`${BASE_URL}/api${endpoint}`, body, { params: queryParams });
  return res.data;
};

const _del = async (endpoint, token = getAuthToken()) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  const res = await axios.delete(`${BASE_URL}/api${endpoint}`);
  return res.data;
};

export const get = async (endpoint, queryParams = {}, options, token = getAuthToken()) => {
  try {
    return await _get(endpoint, queryParams, options, token);
  } catch (error) {
    if (error?.response?.status === 401 && getAuthToken()) {
      const validRefresh = await refreshTokenRequest();

      if (validRefresh) {
        return await _get(endpoint, queryParams, options);
      } else {
        redirectLogin();
      }
    }

    throw error;
  }
};

export const blob = async (endpoint, queryParams = {}, options, token = getAuthToken()) => {
  try {
    return await _blob(endpoint, queryParams, options, token);
  } catch (error) {
    if (error?.response?.status === 401 && getAuthToken()) {
      const validRefresh = await refreshTokenRequest();

      if (validRefresh) {
        return await _blob(endpoint, queryParams, options);
      } else {
        redirectLogin();
      }
    }

    throw error;
  }
};

export const post = async (endpoint, body = {}, queryParams = {}, options, token = getAuthToken()) => {
  try {
    return await _post(endpoint, body, queryParams, options, token);
  } catch (error) {
    if (error?.response?.status === 401 && getAuthToken()) {
      const validRefresh = await refreshTokenRequest();

      if (validRefresh) {
        return await _post(endpoint, body, queryParams, options);
      } else {
        redirectLogin();
      }
    }

    throw error;
  }
};

export const patch = async (endpoint, body = {}, queryParams = {}, token = getAuthToken()) => {
  try {
    return await _patch(endpoint, body, queryParams, token);
  } catch (error) {
    if (error?.response?.status === 401 && getAuthToken()) {
      const validRefresh = await refreshTokenRequest();

      if (validRefresh) {
        return await _patch(endpoint, body, queryParams);
      } else {
        redirectLogin();
      }
    }

    throw error;
  }
};

export const put = async (endpoint, body = {}, queryParams = {}, token = getAuthToken()) => {
  try {
    return await _put(endpoint, body, queryParams, token);
  } catch (error) {
    if (error?.response?.status === 401 && getAuthToken()) {
      const validRefresh = await refreshTokenRequest();

      if (validRefresh) {
        return await _put(endpoint, body, queryParams);
      } else {
        redirectLogin();
      }
    }

    throw error;
  }
};

export const del = async (endpoint, token = getAuthToken()) => {
  try {
    return await _del(endpoint, token);
  } catch (error) {
    if (error?.response?.status === 401 && getAuthToken()) {
      const validRefresh = await refreshTokenRequest();

      if (validRefresh) {
        return await _del(endpoint);
      } else {
        redirectLogin();
      }
    }

    throw error;
  }
};
