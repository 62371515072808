import { del, get, post, put } from './request';

class Guest {
  static list() {
    return get('/guests').then((res) => res.data);
  }

  static listWithParams(queryParams) {
    return get('/guests', queryParams).then((res) => (queryParams?.csv ? res : res.data));
  }

  static listTimeZones() {
    return get('/guests/time-zones').then((res) => res.data);
  }

  static delete(id) {
    return del(`/guests/${id}`).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/guests/${id}`, data).then((res) => res.data);
  }

  static invite(id) {
    return post(`/guests/${id}/invite`).then((res) => res.data);
  }

  static getThemesEvergreen(evergreenLink) {
    return get(`/guests/${evergreenLink}/themes`).then((res) => res.data);
  }

  static createEvergreen({ id, body }) {
    return post(`/guests/${id}`, body).then((res) => res.data);
  }

  static getInternalCoaches(id) {
    return get(`/guests/${id}/coaches`).then((res) => res.data);
  }

  static listWithParamsEvergreen(id, queryParams, coacheeParams) {
    return get(`/guests/${id}/coaches`, { ...queryParams, ...coacheeParams }).then((res) => res.data);
  }

  static listWithParamsSelfRegistration(id, queryParams, coacheeParams) {
    console.log('listWithParamsSelfRegistration', id);
    return get(`/guests/self-registration/${id}/coaches`, { ...queryParams, ...coacheeParams }).then((res) => res.data);
  }

  static getSelfRegistrationTheme({ selfRegistration, themeId }) {
    return get(`/guests/self-registration/${selfRegistration}/theme/${themeId}`).then((res) => res.data);
  }

  static getSelfRegistrationCoaches({ selfRegistration }) {
    return get(`/guests/self-registration/${selfRegistration}/coaches`).then((res) => res.data);
  }

  static rhValidation({ id }) {
    return post(`/guests/${id}/validate`).then((res) => res.data);
  }
}

export default Guest;
