const styles = {
  container: {
    maxWidth: '700px',
    height: 'max-content',
  },

  title: {
    fontSize: '24px',
    fontWeight: 600,
    paddingBottom: '16px',
  },

  titleCategory: {
    fontSize: '20px',
    fontWeight: 600,
    paddingBottom: '16px',
  },

  subTitle: {
    color: '#545454',
    fontSize: '16px',
    fontWeight: 300,
    paddingBottom: '0px',
  },

  content: {
    color: 'grey',
    marginBottom: '18px',
    fontSize: '16px',
    fontWeight: 300,
  },

  copyButton: {
    height: '52px',
  },

  divider: {
    margin: '32px 0',
  },

  uploaderXlsx: {
    marginTop: '24px',
    height: '100px',
  },

  backFirstStepContainer: {
    marginTop: '32px',
    justifyContent: 'right',
    display: 'flex',
  },
};
export default styles;
