import React from 'react';

import Button from '../Button/Button';
import DownloadIcon from '@mui/icons-material/Download';

import { useTranslation } from 'react-i18next';

const ExportButton = ({ label, onClick }) => {
  const { t } = useTranslation();

  return (
    <Button startIcon={<DownloadIcon />} variant="secondary" onClick={onClick}>
      {label || t('export_data')}
    </Button>
  );
};

export default ExportButton;
