import { get, post, put, del, blob } from './request';

class Company {
  static list() {
    return get('/companies').then((res) => res.data);
  }

  static listWithParams(queryParams) {
    return get('/companies', queryParams).then((res) => res.data);
  }

  static listRHsWithParams(id, queryParams) {
    return get(`/companies/${id}/rhs`, queryParams).then((res) => (queryParams?.csv ? res : res.data));
  }

  static listEmailTemplatesWithParams(id, queryParams) {
    return get(`/companies/${id}/email_templates`, queryParams).then((res) => res.data);
  }

  static get(id) {
    return get(`/companies/${id}`).then((res) => res.data);
  }

  static getTaretsTemplateItems(companyId) {
    return get(`/companies/${companyId}/target_template_items`).then((res) => res.data);
  }

  static create(data) {
    return post('/companies', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/companies/${id}`, data).then((res) => res.data);
  }

  static delete(id) {
    return del(`/companies/${id}`).then((res) => res.data);
  }

  static listTheme(id, queryParams) {
    return get(`/companies/${id}/themes`, queryParams).then((res) => res.data);
  }

  static getSelfRegistrationCode(companyId) {
    return get(`/companies/${companyId}/self-registration-code`).then((res) => res.data);
  }

  static generateMenteeImportXlsx(companyId) {
    return blob(`/companies/${companyId}/generate-mentee-import-xlsx`);
  }

  static generateCoachImportXlsx(companyId) {
    return blob(`/companies/${companyId}/generate-coach-import-xlsx`);
  }

  static uploadCoachXlsx(companyId, data, sendEmail = false, verificationOnly = false) {
    return post(
      `/companies/${companyId}/upload-coach-xlsx?sendEmail=${sendEmail}&verificationOnly=${verificationOnly}`,
      data
    ).then((res) => res.data);
  }

  static uploadCoacheeXlsx(companyId, data, sendEmail = false, verificationOnly = false) {
    return post(
      `/companies/${companyId}/upload-coachee-xlsx?sendEmail=${sendEmail}&verificationOnly=${verificationOnly}`,
      data
    ).then((res) => res.data);
  }
}

export default Company;
