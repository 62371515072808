import React, { useState, useRef } from 'react';
import Stack from '@mui/material/Stack';
import styles from './Users.styles';
import { useTranslation } from 'react-i18next';
import TabNavigation from '../program/components/TabNavigationUser';
import { useNavigate } from 'react-router-dom';
import AddUsersModal from './components/AddUsersModal';
import SelfRegistrationLinkModal from './components/SelfRegistrationLinkModal/SelfRegistrationLinkModal';
import { useMeQuery, useListGuestsWithParams, useListCoachesWithParams } from 'hooks/queries';
import { useGuestsCsv } from 'hooks/usecase/useGuests';
import { Box, Typography } from '@mui/material';
import Experts from './Experts';
import Coachees from './Coachees';
import Admins from './Admins';
import { Roles } from 'utils/roles';
import AddIcon from '@mui/icons-material/Add';
import Button from 'ui/components/Button/Button';
import { t } from 'i18next';
import ExportButton from 'ui/components/ExportButton/ExportButton';
import { useExpertsCsv } from 'hooks/usecase/useExperts';
import { useAdminsCsv } from 'hooks/usecase/useAdmins';

const getOptionsTab = (
  expertLabel,
  clientLabel,
  selfRegistrationActivated,
  evergreenManualValidation,
  role,
  countRhValidationCoachee,
  countRhValidationCoaches
) => {
  return [
    { id: 'tab-1', text: expertLabel.toUpperCase(), selected: true },
    { id: 'tab-2', text: clientLabel.toUpperCase(), selected: false },
    ...(role === Roles.RH_MANAGER ? [{ id: 'tab-3', text: 'Admin'.toUpperCase(), selected: false }] : []),
    ...(selfRegistrationActivated || evergreenManualValidation
      ? [
          {
            id: 'tab-4',
            text: t('application_request_tab', { clientLabel }),
            selected: false,
            badge: countRhValidationCoachee,
          },
        ]
      : []),
    ...(evergreenManualValidation
      ? [
          {
            id: 'tab-5',
            text: t('application_request_coach_tab', { expertLabel }),
            selected: false,
            badge: countRhValidationCoaches,
          },
        ]
      : []),
  ];
};

const Users = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const navigate = useNavigate();
  const [isOpenInvitationLink, setIsOpenInvitationLink] = useState(false);
  const [isOpenSelfRegistration, setIsOpenSelfRegistration] = useState(false);
  const { data: user } = useMeQuery();
  const adminsRef = useRef();

  const { downloadCsv: downloadCsvExperts } = useExpertsCsv();
  const { downloadCsv: downloadCsvAdmin } = useAdminsCsv(user.companyId);
  const { downloadCsv: downloadCsvCoachees } = useGuestsCsv();

  const renderContent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return <Experts role={user.role} user={user} company={user?.company} />;
      case 'tab-2':
        return <Coachees role={user.role} />;
      case 'tab-3':
        return <Admins ref={adminsRef} companyId={user ? user.companyId : ''} role={user.role} />;
      case 'tab-4':
        return (
          <Coachees
            role={user.role}
            typeCoachees="RH_Validation"
            noDataDescription={t('no_candidatures_to_be_validated')}
            visibleColumns={['user', 'scopeName', 'createdAt', 'actionsRhValidationSelfRegistration']}
          />
        );
      case 'tab-5':
        return (
          <Experts
            role={user.role}
            user={user}
            company={user?.company}
            rhValidation
            noDataDescription={t('no_candidatures_to_be_validated')}
            visibleColumns={['user', 'scopeName', 'registrationDate', 'actionsRhValidationSelfRegistration']}
          />
        );
      default:
        break;
    }
  };

  const { expertLabel, clientLabel } = user.company || {};

  const getInvitationButtonText = () => {
    switch (selectedTab) {
      case 'tab-1':
        return `${t('add')} ${expertLabel}(s)`;
      case 'tab-2':
        return `${t('add')} ${clientLabel}(s)`;
      case 'tab-3':
        return `${t('add')} ADMIN`;
      case 'tab-4':
        return `${t('generate_application')}`;
      case 'tab-5':
        return `${t('add')} ${t('user')}`;

      default:
        break;
    }
  };

  const { company } = user;

  const { data } = useListGuestsWithParams(
    { type: 'RH_Validation', page: 1, size: 25 },
    { enabled: !!(company.selfRegistrationActivated || company.evergreenManualValidation) }
  );
  const { data: coachData } = useListCoachesWithParams(
    { rhValidation: true, page: 1, size: 10 },
    { enabled: !!company.evergreenManualValidation }
  );

  const countRhValidationCoachee = data?.totalItems || 0;

  const countRhValidationCoaches = coachData?.totalItems || 0;

  const onExportData = () => {
    console.log('onExportData');

    if (selectedTab === 'tab-1') {
      downloadCsvExperts();
    } else if (selectedTab === 'tab-2') {
      downloadCsvCoachees();
    } else if (selectedTab === 'tab-3') {
      downloadCsvAdmin();
    }
  };

  return (
    <Stack sx={{ padding: '30px 60px' }}>
      {/** Header */}
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ backgroundColor: 'white', padding: '10px 38px' }}>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <Typography sx={styles.fontHeader}>{t('users')}</Typography>
            </div>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}>
              {['tab-1', 'tab-2', 'tab-3'].includes(selectedTab) && <ExportButton onClick={onExportData} />}
              <Button
                onClick={() => {
                  if (selectedTab === 'tab-4') {
                    setIsOpenSelfRegistration(true);
                  } else if (selectedTab !== 'tab-3') {
                    setIsOpenInvitationLink(true);
                  } else {
                    adminsRef.current.handleAddAdmin();
                  }
                }}
                startIcon={<AddIcon />}>
                {getInvitationButtonText()}
              </Button>
            </Box>
          </div>
          <div>
            <TabNavigation
              isTruncateTab={false}
              options={getOptionsTab(
                user?.company?.expertLabel,
                user?.company?.clientLabel,
                user?.company?.selfRegistrationActivated,
                user?.company?.evergreenManualValidation,
                user.role,
                countRhValidationCoachee,
                countRhValidationCoaches
              )}
              onChange={(tab) => {
                setSelectedTab(tab);
              }}
              onReturn={() => navigate(-1)}
            />
          </div>
        </div>
      </Stack>
      {renderContent()}
      {/** Modal to delete */}
      <AddUsersModal
        companyId={user?.companyId}
        userType={selectedTab === 'tab-1' ? 'coach' : 'coachee'}
        expertLabel={user?.company?.expertLabel}
        clientLabel={user?.company?.clientLabel}
        applicationLink={user ? user.company.applicationLink : {}}
        open={isOpenInvitationLink}
        onClose={() => setIsOpenInvitationLink(false)}
        onValidate={() => {
          setIsOpenInvitationLink(false);
        }}
      />
      <SelfRegistrationLinkModal
        applicationLink={user?.company?.applicationLink || ''}
        companyId={user?.companyId || ''}
        selfRegistrationLink={user?.company?.selfRegistrationLink || ''}
        open={isOpenSelfRegistration}
        onClose={() => setIsOpenSelfRegistration(false)}
      />
    </Stack>
  );
};

export default Users;
