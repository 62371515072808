import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useExperts } from 'hooks/usecase/useExperts';
import { useNavigate } from 'react-router-dom';
import DeleteModal from 'ui/pages/users/components/DeleteModal';
import DataGrid from 'ui/components/DataGrid';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';

// Configs
import { tableSchemaBase as columns, sampleDataBase as rows } from './Experts.config';

const Experts = ({ role, companyId }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [queryParams, setQueryParams] = useState({ page: 1, size: 25, companyId: companyId, rhValidation: false });
  const navigate = useNavigate();
  const [coachToDelete, setCoachToDelete] = useState(null);

  // Hooks
  const { experts, isLoading, isFetched, totalPages, totalItems, handleDeleteCoach } = useExperts(queryParams);
  const updateList = (experts) =>
    experts.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        languagesSpoken: currentItem.languagesSpoken ? currentItem.languagesSpoken.join(', ') : '',
        active: currentItem.active ? 'YES' : 'NO',
      };
      acc.push(modifiedItem);
      return acc;
    }, []);

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, userScope: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'userScope' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  return (
    <Stack sx={{ height: '630px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '72px' }}>
        {/** <Button
          onClick={() => {
            // setIsOpenAddModal(true);
          }}
          sx={{ marginTop: '10px', minWidth: '170px', backgroundColor: 'primary.dark' }}
          variant="contained">
          {t(ADD) + ' ' + 'EXPERT'}
        </Button> */}
      </Box>

      {/** DataGrid */}
      <DataGrid
        columns={columns}
        rows={updateList(experts)}
        role={role}
        onDelete={(item) => {
          setIsOpen(true);
          setCoachToDelete(item);
        }}
        totalItems={totalItems}
        onShow={(coach) => navigate(`/coach/${coach.id}`)}
        onRowClicked={(coach, event) => {
          if (!event.defaultMuiPrevented) {
            navigate(`/coach/${coach.id}`);
          }
        }}
        onFilter={handleOnFilter}
        onEdit={() => {}}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        totalPages={totalPages ? totalPages : 0}
        loading={isLoading}
      />
      {/** Modal to delete */}
      <DeleteModal
        itemToDelete={coachToDelete}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onValidate={() => {
          handleDeleteCoach({ id: coachToDelete.id });
          setIsOpen(false);
        }}
      />
    </Stack>
  );
};

export default Experts;
