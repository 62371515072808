import React, { useState } from 'react';

import { Badge, Button, Grid } from '@mui/material';

import styles from './TabNavigation.styles';
import { truncateString } from '../../../../../common/utils';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'ui/components/shared/GoBackButton';

const TabNavigation = ({ returnTab, onChange, options, onReturn, isSubTab, customStyle, isTruncateTab = true }) => {
  const [optionsState, setOptionState] = useState(options);
  const [selectedTab, setSelectedTab] = useState('');
  const { t } = useTranslation();

  const handleSelectTab = (id) => {
    const tabs = options.map((item) => {
      return { ...item, selected: false };
    });

    const updatedItems = tabs.map((item) => {
      if (item.id === id) {
        setSelectedTab(item.id);
        return { ...item, selected: true };
      }
      return item;
    });

    setOptionState(updatedItems);
  };

  const isSelected = (id) => {
    const selectedItem = optionsState.find((item) => item.id === id);
    return selectedItem ? selectedItem.selected : false;
  };

  return (
    <Grid sx={styles.container} container spacing={1}>
      {returnTab && (
        <Grid item>
          <GoBackButton
            onClick={() => {
              onReturn();
            }}
            sx={[styles.tab, styles.unselectedTab]}
          />
        </Grid>
      )}
      {options &&
        options.map((item) => (
          <Grid item key={item.id}>
            <Badge
              style={{ margin: 0 }}
              sx={{ '.MuiBadge-badge': { top: '8px !important', right: '-1px' } }}
              color="error"
              badgeContent={item.badge == 0 ? null : item.badge}
              max={9}>
              <Button
                onClick={() => {
                  handleSelectTab(item.id);
                  onChange(item.id);
                }}
                sx={[
                  customStyle ? customStyle.tab : styles.tab,
                  isSubTab && styles.subTab,
                  isSelected(item.id) ? styles.selectedTab : styles.unselectedTab,
                ]}
                variant="text">
                {isTruncateTab ? truncateString(item.text, 25) : item.text}
              </Button>
            </Badge>
          </Grid>
        ))}
    </Grid>
  );
};

export default TabNavigation;
