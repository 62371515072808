import { useEffect, useState } from 'react';
import { useListCoachesWithParams, useListCoachesWithParamsCsv } from 'hooks/queries';
import { useMutation } from '@tanstack/react-query';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { t } from 'i18next';
import FileSaver from 'file-saver';
import { useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const useExperts = (queryParams) => {
  // React query
  const { data, isLoading, isFetched, refetch } = useListCoachesWithParams(queryParams);
  const { setStateAlert } = useAlert();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data) {
      console.log('RESPONSE API ::: ', data);
    }
  }, [data]);

  const updateList = (users) =>
    users.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        name: currentItem.firstname + ' ' + currentItem.lastname,
        user: {
          name: currentItem.firstname + ' ' + currentItem.lastname,
          title: currentItem.title,
          picture: currentItem.picture,
        },
        scopeName:
          currentItem.scopeName != null
            ? currentItem.scopeName
            : currentItem.userScope
            ? currentItem.userScope.name
            : '',
        internalCoach: currentItem.internalCoach ? 'INTERNAL' : 'EXTERNAL',
        registrationDate: dayjs(currentItem.registrationDate).fromNow(),
      };

      acc.push(modifiedItem);
      return acc;
    }, []);

  const mutationDelete = useMutation(api.Coaches.delete, {
    onSuccess: async (_data, id) => {
      queryClient.invalidateQueries(['get-list-coaches-params']);
      refetch();
      setStateAlert({ open: true, message: t('user_deleted_successfully') });
    },
    onError: (error) => {
      queryClient.invalidateQueries(['get-list-coaches-params']);
      setStateAlert({ open: true, message: `${error.response.data.error}` });
    },
  });

  const handleDeleteCoach = ({ id }) => {
    return mutationDelete.mutate(id);
  };

  const mutationValidateCoach = useMutation(api.Coaches.rhValidation, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-coaches-params']);
      setStateAlert({
        open: true,
        message: t('validation-self-registration'),
      });
      refetch();
    },
    onError: (error) => {
      queryClient.invalidateQueries(['get-list-coaches-params']);
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
      refetch();
    },
  });

  const onValidateCoachSelfRegistration = (coachId) => {
    mutationValidateCoach.mutate(coachId);
  };

  return {
    experts: data ? updateList(data.rows) : [],
    totalPages: data && data.totalPages,
    totalItems: data && data.totalItems,
    isLoading,
    isFetched,
    handleDeleteCoach,
    onValidateCoachSelfRegistration,
    refetch,
  };
};

export const useExpertsCsv = () => {
  const [authorizeExportDataDownload, setAuthorizeExportDataDownload] = useState(false);

  const {
    data: dataCsv,
    refetch: refetchCsv,
    isLoading: isLoadingExportData,
    isRefetching,
  } = useListCoachesWithParamsCsv({ csv: true, rhValidation: false });

  useEffect(() => {
    if (dataCsv && authorizeExportDataDownload && !isLoadingExportData && !isRefetching) {
      const blob = new Blob([dataCsv], { type: 'text/csv' });
      FileSaver.saveAs(blob, `users-coaches.csv`);
      setAuthorizeExportDataDownload(false);
    }
  }, [dataCsv, authorizeExportDataDownload, isLoadingExportData, isRefetching]);

  const downloadCsv = () => {
    setAuthorizeExportDataDownload(false);

    // Waits for inite params to be updated in query hook
    setTimeout(() => {
      refetchCsv();
      setAuthorizeExportDataDownload(true);
    }, 100);
  };

  return {
    downloadCsv,
  };
};
